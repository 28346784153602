.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  color: red !important;
}
.rdrInRange {
  color: red !important;
}
.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: red;
}
