.MuiOutlinedInput-notchedOutline {
  border-color: #9d9ea0 !important;
}
.MuiInputLabel-root {
  color: "rgba(255, 255, 255, 0.87)";
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
