@font-face {
  font-family: "RocGrotesk";
  src: local("RocGrotesk"), url("./assets/fonts/RocGrotesk.otf") format("truetype");
  font-weight: normal;
  font-style: initial;
}

@font-face {
  font-family: "Setimo";
  src: local("Setimo"),
    url("assets/fonts/Setimo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SetimoBold";
  src: local("SetimoBold"), url("./assets/fonts/SetimoBold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "SetimoLight";
  src: local("SetimoLight"), url("./assets/fonts/SetimoLight.ttf") format("truetype");
  font-weight: lighter;
}

body {
  font-family: "Setimo" !important;
  print-color-adjust: exact;
}

